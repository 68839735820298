import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const data = useStaticQuery(graphql`
    query BioQueryForLayout {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
            note
          }
        }
      }
    }
  `)
  let header
  const social = data.site.siteMetadata?.social

  // headerの表示切り替えのコード

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  // header = (
  //   <>
  //     <h2>
  //       <Link to="/">{title}</Link>
  //     </h2>
  //     <div className="icons">
  //       <a
  //         className="icon_tw"
  //         href={`https://twitter.com/${social?.twitter || ``}`}
  //       >
  //         <StaticImage
  //           layout="fixed"
  //           src="../images/icon_twitter.png"
  //           width={36}
  //           height={36}
  //           quality={95}
  //           alt="Profile picture"
  //         />
  //       </a>
  //       <a
  //         className="icon_git"
  //         href={`https://github.com/${social?.github || ``}`}
  //       >
  //         <StaticImage
  //           layout="fixed"
  //           src="../images/icon_git.png"
  //           width={36}
  //           height={36}
  //           quality={95}
  //           alt="Profile picture"
  //         />
  //       </a>
  //     </div>
  //   </>
  // )

  header = (
    <>
      <div className="header-container"> {/* このdivタグを追加 */}
        <h2>
          <Link to="/">{title}</Link>
        </h2>
        <div className="icons"> {/* クラス名を変更 */}
          <a
            className="icon_tw" // クラス名を変更
            href={`https://twitter.com/${social?.twitter || ``}`}
          >
            <StaticImage
              layout="fixed"
              src="../images/icon_twitter.png"
              width={36}
              height={36}
              quality={95}
              alt="Profile picture"
            />
          </a>
          <a
            className="icon_git" // クラス名を変更
            href={`https://github.com/${social?.github || ``}`}
          >
            <StaticImage
              layout="fixed"
              src="../images/icon_git.png"
              width={36}
              height={36}
              quality={95}
              alt="Profile picture"
            />
          </a>
        </div>
      </div> {/* このdivタグを追加 */}
    </>
  )
  
  // 省略...
  

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>© {new Date().getFullYear()} kakeru tokunaga</footer>
    </div>
  )
}

export default Layout
